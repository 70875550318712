import { createSlice } from '@reduxjs/toolkit';

import {
    sendAnalyticsAllMemberActivity,
    sendAnalyticsClientSearchTypeRegion,
    sendAnalyticsClientSearchAdvance,
    sendAnalyticsClientSearched
} from './actions';

const initialState = {
	loading: 'idle' | 'pending' | 'succeeded' | 'failed',
	error: null,
	msg: '',
};

export const analyticsSlice = createSlice({
	name: 'analytics',
	initialState,
	reducers: {},

	extraReducers: (builder) => {
		builder
			.addCase(sendAnalyticsAllMemberActivity.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(sendAnalyticsAllMemberActivity.fulfilled, (state, action) => {
				state.loading = 'succeeded';
				state.msg = ' ';
			})
			.addCase(sendAnalyticsAllMemberActivity.rejected, (state, action) => {
				state.loading = 'failed';
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(sendAnalyticsClientSearchTypeRegion.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(sendAnalyticsClientSearchTypeRegion.fulfilled, (state, action) => {
				state.loading = 'succeeded';
				state.msg = ' ';
			})
			.addCase(sendAnalyticsClientSearchTypeRegion.rejected, (state, action) => {
				state.loading = 'failed';
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(sendAnalyticsClientSearchAdvance.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(sendAnalyticsClientSearchAdvance.fulfilled, (state, action) => {
				state.loading = 'succeeded';
				state.msg = ' ';
			})
			.addCase(sendAnalyticsClientSearchAdvance.rejected, (state, action) => {
				state.loading = 'failed';
				state.error = action?.payload;
				state.msg = action?.message;
			})
			.addCase(sendAnalyticsClientSearched.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(sendAnalyticsClientSearched.fulfilled, (state, action) => {
				state.loading = 'succeeded';
				state.msg = ' ';
			})
			.addCase(sendAnalyticsClientSearched.rejected, (state, action) => {
				state.loading = 'failed';
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
	},
});

export default analyticsSlice.reducer;
