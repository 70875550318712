import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/api";

//  Get Heart ranking list
export const getAllHeartRanks = createAsyncThunk(
	"hearts/getAllHeartRanks",
	async (params, { rejectWithValue }) => {
		try {
			const response = await API.get("/product/heart/ranks", params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

//  purchase heart
export const purchaseHearts = createAsyncThunk(
	"hearts/purchaseHearts",
	async (params, { rejectWithValue }) => {
		try {
			const response = await API.post(`/product/heart/purchase`, params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data.error);
		}
	}
);
//  send  heart
export const sendHearts = createAsyncThunk(
	"hearts/sendHearts",
	async (params, { rejectWithValue }) => {
		try {
			const response = await API.post(`/product/heart/send`, params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data.error);
		}
	}
);
//  add  heart
export const addHearts = createAsyncThunk(
	"hearts/addHearts",
	async (params, { rejectWithValue }) => {
		try {
			const response = await API.post(`/product/heart/add`, params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

//    heart balance
export const getHeartBalance = createAsyncThunk(
	"hearts/getHeartBalance",
	async (signal, rejectWithValue) => {
		try {
			const response = await API.get(`/product/heart/balance`, { signal });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

//   heart transactions
export const getHeartTransactions = createAsyncThunk(
	"hearts/getHeartTransactions",
	async (params, { rejectWithValue }) => {
		try {
			const response = await API.get(`/product/heart/transactions`, {
				params,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
//   heart histories
export const getHeartHistories = createAsyncThunk(
	"hearts/getHeartHistories",
	async (params, { rejectWithValue }) => {
		try {
			const response = await API.get(`/product/heart/histories`, params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

//   heart rank
export const getHeartRank = createAsyncThunk(
	"hearts/getHeartRank",
	async (params, { rejectWithValue }) => {
		try {
			const response = await API.get(`/product/heart/rank`, params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
