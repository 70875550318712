import { createContext, useContext, useState } from "react";
const AuthContext = createContext(null);

// check user auth credentials
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    isLoginMobile: false,
    created: null,
    email: null,
    membership: null,
    mobile: null,
    modified: null,
    photo: null,
    username: null,
    zh_name: null,
  });

  const logout = () => {
    setAuth({
      isLoginMobile: false,
      created: null,
      email: null,
      membership: null,
      mobile: null,
      modified: null,
      photo: null,
      username: null,
      zh_name: null,
    });
  };

  return (
    <AuthContext.Provider value={{ auth, setAuth, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
