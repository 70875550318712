import { createSlice } from "@reduxjs/toolkit";

import {
  getAllPostReviews,
  getAllPostReviewsReward,
  addPostReviews,
  getPostReviewsDetails,
  getPostReviewsComments,
  addPostReviewsComments,
  editPostReviewsComments,
  deletePostReviewsComments,
  getTop10PostReviews,
  uploadProfilePhoto,
  getAllMyInstantReviews,
  getClientInstantReviews,
  deleteMyInstantReviews,
  uploadVoices,
  instantReviewLike,
  getInstantReviewRewards,
  getAllPostReviewsLogin,
  getMemberClientinstRevs,
  uploadImages,
} from "./actions";

const initialState = {
  postReviews: {},
  postReviewReward: {},
  topTenPost: {},
  postReviewDetails: {},
  comments: {},
  myInstRev: {},
  myInstRevLogin: {},
  myInstRevClient: {},
  memberClientInstRev: {},
  addPostReview: null,
  addComment: null,
  deleteComment: null,
  editComment: null,
  photos: null,
  voices: null,
  liked: null,
  uploaded: null,
  deletePost: null,
  loading: "idle" | "pending" | "succeeded" | "failed",
  error: null,
  msg: "",
  isLoggedIn: false,
};

export const postReviewsSlice = createSlice({
  name: "postReviews",
  initialState,
  reducers: {
    logoutSuccess: (state) => {
      state.isLoggedIn = false;
      localStorage.removeItem("userInfo");
      localStorage.removeItem("isLoggedIn");
    },
    updateData: (state, action) => {
      state = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllPostReviews.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllPostReviews.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.postReviews = action.payload;
        state.msg = "";
      })
      .addCase(getAllPostReviews.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getAllPostReviewsLogin.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllPostReviewsLogin.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.myInstRevLogin = action.payload;
        state.msg = "";
      })
      .addCase(getAllPostReviewsLogin.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getAllMyInstantReviews.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllMyInstantReviews.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.myInstRev = action.payload;
        state.msg = "";
      })
      .addCase(getAllMyInstantReviews.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getMemberClientinstRevs.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getMemberClientinstRevs.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.memberClientInstRev = action.payload;
        state.msg = "";
      })
      .addCase(getMemberClientinstRevs.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getClientInstantReviews.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getClientInstantReviews.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.myInstRevClient = action.payload;
        state.msg = "";
      })
      .addCase(getClientInstantReviews.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(deleteMyInstantReviews.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deleteMyInstantReviews.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.deletePost = action.payload;
        state.msg = "";
      })
      .addCase(deleteMyInstantReviews.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getTop10PostReviews.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getTop10PostReviews.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.topTenPost = action.payload;
        state.msg = "";
      })
      .addCase(getTop10PostReviews.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getInstantReviewRewards.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getInstantReviewRewards.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.postReviewReward = action.payload;
        state.msg = "";
      })
      .addCase(getInstantReviewRewards.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getPostReviewsDetails.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getPostReviewsDetails.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.postReviewDetails = action.payload;
        state.msg = "";
      })
      .addCase(getPostReviewsDetails.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(addPostReviews.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addPostReviews.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.addPostReview = action.payload;
        state.msg = "";
      })
      .addCase(addPostReviews.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(uploadProfilePhoto.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(uploadProfilePhoto.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.photos = action.payload;
        state.msg = "";
      })
      .addCase(uploadProfilePhoto.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(addPostReviewsComments.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addPostReviewsComments.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.addComment = action.payload;
        state.msg = "";
      })
      .addCase(addPostReviewsComments.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(editPostReviewsComments.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(editPostReviewsComments.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.editComment = action.payload;
        state.msg = "";
      })
      .addCase(editPostReviewsComments.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(deletePostReviewsComments.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deletePostReviewsComments.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.deleteComment = action.payload;
        state.msg = "";
      })
      .addCase(deletePostReviewsComments.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getPostReviewsComments.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getPostReviewsComments.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.comments = action.payload;
        state.msg = "";
      })
      .addCase(getPostReviewsComments.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(uploadVoices.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(uploadVoices.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.voices = action.payload;
        state.msg = "";
      })
      .addCase(uploadVoices.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(instantReviewLike.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(instantReviewLike.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.liked = action.payload;
        state.msg = "";
      })
      .addCase(instantReviewLike.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(uploadImages.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(uploadImages.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.uploaded = action.payload;
        state.msg = "";
      })
      .addCase(uploadImages.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      });
  },
});

export default postReviewsSlice.reducer;
