import { createAsyncThunk } from "@reduxjs/toolkit";
import apiAnalytics from "../../apis/apiAnalytics";
import { TOKEN } from "../../../context/LocalStrorageKeys";

export const sendAnalyticsAllMemberActivity = createAsyncThunk(
  "analytics/sendAnalyticsAllMemberActivity",
  async (categoryName, {rejectWithValue}) => {
    const path = window.location.href;
    const userType = localStorage.getItem(TOKEN) ? "registered" : "public";
    const payload = {
        path: path,
        category_name: categoryName,
        user_type: userType
    };
    try {
      const response = await apiAnalytics.post("/api/all-member-activity", payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);

export const sendAnalyticsClientSearchTypeRegion = createAsyncThunk(
  "analytics/sendAnalyticsClientSearchTypeRegion",
  async (data, {rejectWithValue}) => {
    try {
      const response = await apiAnalytics.post("/api/client-search-type-region", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);

export const sendAnalyticsClientSearchAdvance = createAsyncThunk(
  "analytics/sendAnalyticsClientSearchAdvance",
  async (data, {rejectWithValue}) => {
    try {
      const response = await apiAnalytics.post("/api/client-search-advance", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);

export const sendAnalyticsClientSearched = createAsyncThunk(
  "analytics/sendAnalyticsClientSearched",
  async (data, {rejectWithValue}) => {
    try {
      const response = await apiAnalytics.post("/api/client-searched", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);