import { createSlice } from "@reduxjs/toolkit";
import { setTooltipId } from "./actions";

const tooltipSlice = createSlice({
  name: "tooltip",
  initialState: {
    currentTooltipId: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setTooltipId, (state, action) => {
      state.currentTooltipId = action.payload;
    });
  },
});

export default tooltipSlice.reducer;
