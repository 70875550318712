import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/api";

export const getInvitationList = createAsyncThunk(
  "invitations/getInvitationList",
  async (params, rejectWithValue) => {
    try {
      const response = await API.post("/member/invitation/list", params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.error?.message);
    }
  }
);
// export const InvitationDetails = createAsyncThunk(
//   "invitations/InvitationDetails",
//   async (notif_id, rejectWithValue) => {
//     try {
//       const response = await API.get(`/system/Invitation/${notif_id}`);

// 		return response.data;
// 	} catch (error) {
// 		return rejectWithValue(error.response.data?.error?.message);
// 	}
// });
// export const deleteInvitation = createAsyncThunk('invitations/deleteInvitation', async (id, rejectWithValue) => {
// 	try {
// 		const response = await API.delete(`/system/Invitation/${id}`);
// 		return response.data;
// 	} catch (error) {
// 		return rejectWithValue(error.response.data?.error?.message);
// 	}
// });
// export const deleteAllReadInvitations = createAsyncThunk('invitations/deleteAllReadInvitations', async (rejectWithValue) => {
// 	try {
// 		const response = await API.delete('/system/Invitation/delete/all', { delete_all: true });

// 		return response.data;
// 	} catch (error) {
// 		return rejectWithValue(error.response.data?.error?.message);
// 	}
// });
