import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/api";

export const getNotificationList = createAsyncThunk(
  "notifications/getNotificationList",
  async (params, rejectWithValue) => {
    try {
      const response = await API.get("/system/notification/list", { params });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.error?.message);
    }
  }
);
export const notificationDetails = createAsyncThunk(
  "notifications/notificationDetails",
  async (notif_id, rejectWithValue) => {
    try {
      const response = await API.get(`/system/notification/${notif_id}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.error?.message);
    }
  }
);
export const deleteNotification = createAsyncThunk(
  "notifications/deleteNotification",
  async (id, {dispatch ,rejectWithValue}) => {
    try {
      const response = await API.delete(`/system/notification/${id}`);
      await dispatch(getNotificationList({ page: 1, per_page: 10 }));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.error?.message);
    }
  }
);
export const deleteAllReadNotifications = createAsyncThunk(
  "notifications/deleteAllReadNotifications",
  async (params, rejectWithValue) => {
    try {
      const response = await API.delete("/system/notification/delete/all_read");

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.error?.message);
    }
  }
);
