import { createSlice } from "@reduxjs/toolkit";

import {
  getAllPosts,
  getInstantPostDetails,
  getInstantPostRewards,
  getCurrentWeekPosts,
  getLastWeekPosts,
  getAllClientPosts,
  getAllFollowingPosts,
  getInstantPostComments,
  addPostInstantComments,
  editPostInstantComments,
  deletePostInstantComments,
  getClientRealPhoto,
  getClientADPhoto,
} from "./actions";

const initialState = {
  posts: {},
  instantPostDetails: null,
  instantPostReward: null,
  currentWeeks: null,
  lastWeeks: null,
  instPostComments: null,
  addComment: null,
  deleteComment: null,
  editComment: null,
  clientPosts: null,
  followingPost: null,
  loading: "idle" | "pending" | "succeeded" | "failed",
  error: null,
  msg: "",
  isLoggedIn: false,
};

export const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    logoutSuccess: (state) => {
      state.isLoggedIn = false;
      localStorage.removeItem("userInfo");
      localStorage.removeItem("isLoggedIn");
    },
    updateData: (state, action) => {
      state = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllPosts.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllPosts.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.posts = action.payload;
        state.msg = "posts data successfully fetched ";
      })
      .addCase(getAllPosts.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getAllClientPosts.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllClientPosts.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.clientPosts = action.payload;
        state.msg = "posts data successfully fetched ";
      })
      .addCase(getAllClientPosts.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
  
      .addCase(getAllFollowingPosts.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllFollowingPosts.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.followingPost = action.payload;
        state.msg = "posts data successfully fetched ";
      })
      .addCase(getAllFollowingPosts.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getCurrentWeekPosts.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getCurrentWeekPosts.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.currentWeeks = action.payload;
        state.msg = "posts data successfully fetched ";
      })
      .addCase(getCurrentWeekPosts.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getLastWeekPosts.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getLastWeekPosts.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.lastWeeks = action.payload;
        state.msg = "posts data successfully fetched ";
      })
      .addCase(getLastWeekPosts.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getInstantPostDetails.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getInstantPostDetails.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.instantPostDetails = action.payload;
        state.msg = "posts data successfully fetched ";
      })
      .addCase(getInstantPostDetails.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getInstantPostRewards.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getInstantPostRewards.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.instantPostReward = action.payload;
        state.msg = "posts data successfully fetched ";
      })
      .addCase(getInstantPostRewards.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getInstantPostComments.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getInstantPostComments.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.instPostComments = action.payload;
        state.msg = "posts data successfully fetched ";
      })
      .addCase(getInstantPostComments.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(addPostInstantComments.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addPostInstantComments.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.addComment = action.payload;
        state.msg = "posts data successfully fetched ";
      })
      .addCase(addPostInstantComments.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(editPostInstantComments.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(editPostInstantComments.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.editComment = action.payload;
        state.msg = "posts data successfully fetched ";
      })
      .addCase(editPostInstantComments.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(deletePostInstantComments.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deletePostInstantComments.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.deleteComment = action.payload;
        state.msg = "posts data successfully fetched ";
      })
      .addCase(deletePostInstantComments.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      });
  },
});

export const { logoutSuccess, updateData } = postsSlice.actions;
export default postsSlice.reducer;
