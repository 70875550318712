import { createSlice } from "@reduxjs/toolkit";

import {
getAllSpecialCatalogues,
getAllSpecialCataloguesClients
} from "./actions";

const initialState = {
  specialCat: {},
  specialCatClients: null,
  loading: "idle" | "pending" | "succeeded" | "failed",
  error: null,
  msg: "",

};

export const specialCatalogueSlice = createSlice({
  name: "specialCat",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllSpecialCatalogues.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllSpecialCatalogues.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.specialCat = action.payload;
        state.msg = "Special Catalogue data successfully fetched ";
      })
      .addCase(getAllSpecialCatalogues.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getAllSpecialCataloguesClients.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllSpecialCataloguesClients.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.specialCatClients = action.payload;
        state.msg = "Special Catalogue data successfully fetched ";
      })
      .addCase(getAllSpecialCataloguesClients.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      });
  },
});

// export const { logoutSuccess, updateData } = postsSlice.actions;
export default specialCatalogueSlice.reducer;
