import { createSlice } from "@reduxjs/toolkit";

import {
  getInvitationList
} from "./actions";

const initialState = {
  invitations: {},
  // notiDetails: null,
  // deleteNoti: null,
  // deleteAllNoti: null,
  loading: "idle" | "pending" | "succeeded" | "failed",
  error: null,
  msg: "",
};

export const invitationSlice = createSlice({
  name: "invitations",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getInvitationList.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getInvitationList.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.invitations = action.payload;
        state.msg = " ";
      })
      .addCase(getInvitationList.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action?.payload;
        state.msg = action?.message;
      });
      // .addCase(notificationDetails.pending, (state) => {
      //   state.loading = "pending";
      // })
      // .addCase(notificationDetails.fulfilled, (state, action) => {
      //   state.loading = "succeeded";
      //   state.notiDetails = action.payload;
      //   state.msg = " ";
      // })
      // .addCase(notificationDetails.rejected, (state, action) => {
      //   state.loading = "failed";
      //   state.error = action.payload?.message;
      //   state.msg = action?.message;
      // })
      // .addCase(deleteNotification.pending, (state) => {
      //   state.loading = "pending";
      // })
      // .addCase(deleteNotification.fulfilled, (state, action) => {
      //   state.loading = "succeeded";
      //   state.deleteNoti = action.payload;
      //   state.msg = " ";
      // })
      // .addCase(deleteNotification.rejected, (state, action) => {
      //   state.loading = "failed";
      //   state.error = action.payload?.message;
      //   state.msg = action?.message;
      // })
      // .addCase(deleteAllReadNotifications.pending, (state) => {
      //   state.loading = "pending";
      // })
      // .addCase(deleteAllReadNotifications.fulfilled, (state, action) => {
      //   state.loading = "succeeded";
      //   state.deleteAllNoti = action.payload;
      //   state.msg = " ";
      // })
      // .addCase(deleteAllReadNotifications.rejected, (state, action) => {
      //   state.loading = "failed";
      //   state.error = action.payload?.message;
      //   state.msg = action?.message;
      // });
  },
});

export default invitationSlice.reducer;
