import axios from "axios";
import { TOKEN_GUEST, V_KEY } from "../../context/LocalStrorageKeys";

export const APIGuest = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "https://141-api.new-app.site/v1",
  timeout: 30000,
});

export default APIGuest;

APIGuest.interceptors.request.use(async (config) => {
  var X_AUTH_TOKEN = localStorage.getItem(TOKEN_GUEST);
  const X_VERIFY_TOKEN = localStorage.getItem(V_KEY) || "";

  if (X_AUTH_TOKEN !== "") {
    config.headers.set("Authorization", `Bearer ${X_AUTH_TOKEN}`);
  }
  config.headers.set("X-Verify-Key", X_VERIFY_TOKEN);

  return config;
});
