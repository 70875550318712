import axios from "axios";
import { TOKEN, V_KEY } from "../../context/LocalStrorageKeys";
// export default axios.create({
//   baseURL: "https://141-api.new-app.site/v1",
// });

export const API = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	timeout: 30000,
});

export default API;

API.interceptors.request.use(async (config) => {
	var X_AUTH_TOKEN = localStorage.getItem(TOKEN);
	const X_VERIFY_TOKEN = localStorage.getItem(V_KEY) || "";
	if (X_AUTH_TOKEN !== "") {
		config.headers.set("Authorization", `Bearer ${X_AUTH_TOKEN}`);
	}
	config.headers.set("X-Verify-Key", X_VERIFY_TOKEN);

	return config;
});
