import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./features/user/userSlice";
import postsReducer from "./features/posts/postsSlice";
import specialCatalogueSlice from "./features/special-catalogue/specialCatalogueSlice";
import shopsSlice from "./features/shop/shopsSlice";
import postReviewsSlice from "./features/post-review/postReviewsSlice";
import homeSlice from "./features/home/homeSlice";
import flowerSlice from "./features/flower/flowerSlice";
import memberSlice from "./features/member/memberSlice";
import heartSlice from "./features/heart/heartSlice";
import coinSlice from "./features/coin/coinSlice";
import notificationSlice from "./features/notifications/notificationSlice";
import filtersSlice from "./features/filters/filtersSlice";
import invitationSlice from "./features/invitations/invitationSlice";
import analyticsSlice from "./features/analytics/analyticsSlice";
import topTooltipsSlice from "./features/toptooltips/topTooltipsSlice";
import authReducer from "./features/auths/authSlice";
import loaderSlice from "./features/loaders/loaderSlice";
import logger from 'redux-logger'; 

const persistConfig = {
	key: "root",
	storage,
};

export const persistedReducer = persistReducer(persistConfig, authReducer);

const rootReducer = combineReducers({
	home: homeSlice,
	users: userReducer,
	posts: postsReducer,
	specialCat: specialCatalogueSlice,
	shops: shopsSlice,
	postReviews: postReviewsSlice,
	flowers: flowerSlice,
	hearts: heartSlice,
	coins: coinSlice,
	members: memberSlice,
	notifications: notificationSlice,
	filters: filtersSlice,
	invitations: invitationSlice,
	analytics: analyticsSlice,
	tooltip: topTooltipsSlice,
	auth: persistedReducer,
	loader: loaderSlice,
});

const store = configureStore({
	reducer: rootReducer,
	  middleware: (getDefaultMiddleware) =>
	    getDefaultMiddleware({
	      serializableCheck: {
	        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
			ignoredPaths: ['register'],
	      },
	    })
		,
});



export const persistor = persistStore(store);

export default store;
