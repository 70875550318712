import { createSlice } from "@reduxjs/toolkit";
import {
  getAllPostReviewsHome,
  getAllPostsHome,
  getAllShopsHome,
  getAllSpecialCataloguesHome,
  tokenGuest,
  getAllFlowerRanksHome,
  getAllPromotionHome,
  getAllMemberTopRecommendationHome,
  getAllBannerHome,
  getMemberHotelGListHome,
  getMemberForeignersListHome,
} from "./actions";

const initialState = {
  specialCat: null,
  flowers: null,
  homeBanner: null,
  topRecommend: null,
  posts: null,
  hotelG: null,
  foreigners: null,
  shops: null,
  postReviews: null,
  // promotions: null,
  loading: "idle" | "pending" | "succeeded" | "failed",
  error: "",
  guestToken:null,
};
export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(tokenGuest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(tokenGuest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tokenGuest = action.payload;
        state.guestToken = action.payload.user_token;
        localStorage.setItem("TOKEN_GUEST", action.payload.user_token);

        // console.log("ction.payloa  gust",action.payload);
      })
      .addCase(tokenGuest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Special Cat
      .addCase(getAllSpecialCataloguesHome.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllSpecialCataloguesHome.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.specialCat = action.payload;
        state.msg = "Special Catalogue data successfully fetched ";
      })
      .addCase(getAllSpecialCataloguesHome.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      // Flower Ranking
      .addCase(getAllFlowerRanksHome.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllFlowerRanksHome.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.flowers = action.payload;
      })
      .addCase(getAllFlowerRanksHome.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Banners
      .addCase(getAllBannerHome.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllBannerHome.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.homeBanner = action.payload;
        state.msg = "Post Review data successfully fetched ";
      })
      .addCase(getAllBannerHome.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      // Top Recommendation
      .addCase(getAllMemberTopRecommendationHome.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllMemberTopRecommendationHome.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.topRecommend = action.payload;
      })
      .addCase(getAllMemberTopRecommendationHome.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Instant Post
      .addCase(getAllPostsHome.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllPostsHome.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.posts = action.payload;
        state.msg = "posts data successfully fetched ";
      })
      .addCase(getAllPostsHome.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      // Hotel G
      .addCase(getMemberHotelGListHome.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getMemberHotelGListHome.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.hotelG = action.payload;
        state.msg = "Hotel G data successfully fetched ";
      })
      .addCase(getMemberHotelGListHome.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      // foreigners
      .addCase(getMemberForeignersListHome.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getMemberForeignersListHome.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.foreigners = action.payload;
        state.msg = "foreigners data successfully fetched ";
      })
      .addCase(getMemberForeignersListHome.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      // Shops
      .addCase(getAllShopsHome.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllShopsHome.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.shops = action.payload;
        state.msg = "Special Catalogue data successfully fetched ";
      })
      .addCase(getAllShopsHome.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      // Instant Review Post
      .addCase(getAllPostReviewsHome.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllPostReviewsHome.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.postReviews = action.payload;
        state.msg = "Post Review data successfully fetched ";
      })
      .addCase(getAllPostReviewsHome.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      });
  },
});

export default homeSlice.reducer;
