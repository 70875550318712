// third party package
import { createSlice } from "@reduxjs/toolkit";

// components
import {
  createMember,
  loginUsers,
  getOTPCode,
  verifyOTPCode,
  changePasswordUsers,
  changeRegisterMobileUsers,
  changeRegisterEmailUsers,
  getOTPCodeLogin,
  forgetPasswords,
} from "./actions";

const initialState = {
  users: {},
  OTPCode: "",
  OTPCodeLogin: "",
  verifyOTPCode: null,
  tokenGuest: null,
  changePWD: null,
  forgetPWD: null,
  changRegisterMobile: null,
  changRegisterEmail: null,
  loginDetails: "",
  loading: "idle" | "pending" | "succeeded" | "failed",
  error: null,
  msg: "",
  isLoggedIn: false,
  isLogin: false,
  isGuest: false,
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    logoutSuccess: (state) => {
      state.isLoggedIn = false;
      localStorage.removeItem("userInfo");
      localStorage.removeItem("isLoggedIn");
    },
    updateData: (state, action) => {
      state = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createMember.pending, (state) => {
        state.loading = "pending";
        state.msg = "";
      })
      .addCase(createMember.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.users = action.payload;
        state.error = null;

        console.log("action.payload login",action.payload);
        state.msg = action.payload.message;
        localStorage.setItem("EXP_TOKEN", action.payload?.exp_time);
        localStorage.setItem("TOKEN", action.payload?.user_token);
        localStorage.setItem("user_id", action.payload?.user_id);
        localStorage.setItem("isLoggedIn", true);
      })
      .addCase(createMember.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action?.payload;
        state.msg = action?.payload;
      })

      .addCase(loginUsers.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(loginUsers.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.loginDetails = action.payload;
        // state.error = null;

        if (action.payload.role_id === 5) {
          localStorage.setItem("EXP_TOKEN", action.payload?.exp_time);
          localStorage.setItem("TOKEN", action.payload?.user_token);
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("user_id", action.payload?.user_id);
        } else {
          state.error = "This is not a Member Account !!!";
        }
      })
      .addCase(loginUsers.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(changePasswordUsers.pending, (state) => {
        state.loading = "pending";
        // state.users =null
        state.error = null;
      })
      .addCase(changePasswordUsers.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.error = null;
        state.changePWD = action.payload;
      })
      .addCase(changePasswordUsers.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(forgetPasswords.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(forgetPasswords.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.error = null;
        state.forgetPWD = action.payload;
      })
      .addCase(forgetPasswords.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })
      .addCase(changeRegisterMobileUsers.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(changeRegisterMobileUsers.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.error = null;
        state.changRegisterMobile = action.payload;
      })
      .addCase(changeRegisterMobileUsers.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })

      .addCase(changeRegisterEmailUsers.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(changeRegisterEmailUsers.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.error = null;
        state.changRegisterEmail = action.payload;
      })
      .addCase(changeRegisterEmailUsers.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
      })

      .addCase(getOTPCode.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getOTPCode.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.OTPCode = action.payload;
      })
      .addCase(getOTPCode.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getOTPCodeLogin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getOTPCodeLogin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.OTPCodeLogin = action.payload;
      })
      .addCase(getOTPCodeLogin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(verifyOTPCode.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyOTPCode.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.verifyOTPCode = action.payload;
      })
      .addCase(verifyOTPCode.rejected, (state, action) => {
        state.status = "failed";
        state.error = action?.payload;
        state.msg = action?.payload;
      });
  },
});

export const { logoutSuccess, updateData } = userSlice.actions;
export default userSlice.reducer;
