import axios from "axios";
import { TOKEN } from "../../context/LocalStrorageKeys";

export const apiAnalytics = axios.create({
  baseURL: process.env.REACT_APP_ANALYTICS_URL,
  timeout: 30000,
});

export default apiAnalytics;

apiAnalytics.interceptors.request.use(async (config) => {
  var X_AUTH_TOKEN = localStorage.getItem(TOKEN);
  if (X_AUTH_TOKEN !== "") {
    config.headers.set("Authorization", `Bearer ${X_AUTH_TOKEN}`);
  }

  return config;
});
