import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const NotFoundRoute = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/'); // Fallback to the homepage or another route
    }
  };
  return (
    <div className="not-found-container text-white">
      <div className="not-found-content">
        <h1>
            {/* {t("notFound404")} */}

            404 - 页面未找到
        </h1>
        <p>您要查找的页面不存在。</p>
        <Link className="btn btn-primary"  to={"/"}>
        返回主页
        </Link>
      </div>
    </div>
  );
};
export default NotFoundRoute;