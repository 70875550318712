import { createAsyncThunk } from "@reduxjs/toolkit";
import APIGuest from "../../apis/apiGuest";

export const getAllShops = createAsyncThunk(
	"shops/getAllShops",
	async ({ params, signal }, { rejectWithValue }) => {
		// console.log("Shop Params", params);
		try {
			const response = await APIGuest.get("/member/shops", { params, signal });
			return response.data;
		} catch (error) {
			if (error.response.status === 404) {
				return rejectWithValue(error.message);
			}
			if (error.response.status === 500) {
				return rejectWithValue("The server is down. Please try again later.");
			} else {
				return rejectWithValue(error.message.data);
			}
		}
	}
);
export const getAllShopMacau = createAsyncThunk(
	"shops/getAllShopMacau",
	async ({ params, signal }, { rejectWithValue }) => {
		console.log("getAllShopMacau params", params);
		try {
			const response = await APIGuest.post(`/all-recommendation-list`, params, {
				signal,
			});
			return response.data;
		} catch (error) {
			if (error.response.status === 404) {
				return rejectWithValue(error.message);
			}
			if (error.response.status === 500) {
				return rejectWithValue("The server is down. Please try again later.");
			} else {
				return rejectWithValue(error.message.data);
			}
		}
	}
);

export const getShopDetails = createAsyncThunk(
	"shops/getShopDetails",
	async ({ id, signal }, { rejectWithValue, dispatch }) => {
		try {
			const response = await APIGuest.get(`/member/shop/${id}`, { signal });
			return response.data;
		} catch (error) {
			if (error.response.status === 404) {
				return rejectWithValue(error.message);
			}
			if (error.response.status === 500) {
				return rejectWithValue("The server is down. Please try again later.");
			} else {
				return rejectWithValue(error.message.data);
			}
		}
	}
);
