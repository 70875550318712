import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hasShownLoader: JSON.parse(sessionStorage.getItem('hasShownLoader')) || false,
};

const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setHasShownLoader(state) {
      state.hasShownLoader = true;

      console.log("fff", state);
      
      sessionStorage.setItem('hasShownLoader', JSON.stringify(true));
    },
  },
});

export const { setHasShownLoader } = loaderSlice.actions;
export default loaderSlice.reducer;