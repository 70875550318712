import { createSlice } from "@reduxjs/toolkit";

import {
  getAllFlowerRanks,
  getAdBlogDetails,
  purchaseFlowers,
  sendFlowers,
  addFlowers,
  getFlowerBalance,
  getFlowerHistories,
  getFlowerRank,
  getFlowerTransactions,
  getProductIDFlowerAndHeart,
} from "./actions";

const initialState = {
  flowers: {},
  clientAdDetails: null,
  purchase: null,
  send: null,
  add: null,
  balance: 0,
  transactions: null,
  histories: null,
  rank: null,
  productIds: null,

  loading: "idle" | "pending" | "succeeded" | "failed",
  error: null,
  msg: "",
};

export const flowerSlice = createSlice({
  name: "flowers",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllFlowerRanks.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllFlowerRanks.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.flowers = action.payload;
      })
      .addCase(getAllFlowerRanks.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getAdBlogDetails.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAdBlogDetails.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.clientAdDetails = action.payload;
      })
      .addCase(getAdBlogDetails.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(purchaseFlowers.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(purchaseFlowers.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.purchase = action.payload;
      })
      .addCase(purchaseFlowers.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action?.payload;
        state.msg = action.payload;
      })

      .addCase(sendFlowers.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(sendFlowers.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.send = action.payload;
      })
      .addCase(sendFlowers.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
        state.msg = action?.message;
      })

      .addCase(addFlowers.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addFlowers.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.add = action.payload;
      })
      .addCase(addFlowers.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getFlowerBalance.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getFlowerBalance.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.balance = action.payload;
      })
      .addCase(getFlowerBalance.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getFlowerTransactions.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getFlowerTransactions.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.transactions = action.payload;
      })
      .addCase(getFlowerTransactions.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getFlowerHistories.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getFlowerHistories.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.histories = action.payload;
      })
      .addCase(getFlowerHistories.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getFlowerRank.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getFlowerRank.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.rank = action.payload;
      })
      .addCase(getFlowerRank.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getProductIDFlowerAndHeart.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getProductIDFlowerAndHeart.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.productIds = action.payload;
      })
      .addCase(getProductIDFlowerAndHeart.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      });
  },
});

// export const { logoutSuccess, updateData } = postsSlice.actions;
export const { flowers } = flowerSlice.actions;
export default flowerSlice.reducer;
