import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/api";
import APIGuest from "../../apis/apiGuest";

export const getAllPostReviews = createAsyncThunk(
	"postReviews/getAllPostReviews",
	async ({ params, signal }, { rejectWithValue }) => {
		try {
			// const response = await APIGuest.get(`/member/reviews`, { params });
			const response = await APIGuest.post(`/member/instRev/list`, {
				params,
				signal,
			});

			return response.data;
		} catch (error) {
			return rejectWithValue(error.message.data);
		}
	}
);
export const getAllPostReviewsLogin = createAsyncThunk(
	"postReviews/getAllPostReviewsLogin",
	async (params, { rejectWithValue }) => {
		try {
			// const response = await APIGuest.get(`/member/reviews`, { params });
			const response = await API.post(`/user/member/myinstants`, params);

			// console.log("/user/member/myinstants", params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message.data);
		}
	}
);
export const getAllMyInstantReviews = createAsyncThunk(
	"postReviews/getAllMyInstantReviews",
	async (author_id, { rejectWithValue }) => {
		try {
			const response = await API.get(`/user/myinstants?author_id=${author_id}`);

			// console.log("fdshgf");
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message.data);
		}
	}
);
export const getClientInstantReviews = createAsyncThunk(
	"postReviews/getClientInstantReviews",
	async (inst_user, { rejectWithValue }) => {
		try {
			const response = await APIGuest.get(`/user/myinstants?inst_user=${inst_user}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message.data);
		}
	}
);
export const getMemberClientinstRevs = createAsyncThunk(
	"postReviews/getMemberClientinstRevs",
	async (params, { rejectWithValue }) => {
		try {
			const response = await APIGuest.post(`/member/client/instRev/list`, params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message.data);
		}
	}
);
export const getUserInstantReviews = createAsyncThunk(
	"postReviews/getClientInstantReviews",
	async (author_id, { rejectWithValue }) => {
		try {
			const response = await APIGuest.get(`/user/myinstants?author_id=${author_id}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message.data);
		}
	}
);
export const deleteMyInstantReviews = createAsyncThunk(
	"postReviews/deleteMyInstantReviews",
	async (rev_id, { rejectWithValue, dispatch }) => {
		try {
			const response = await API.delete(`/user/instant/${rev_id}`);
			//  await dispatch(getAllMyInstantReviews());
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message.data);
		}
	}
);
export const getTop10PostReviews = createAsyncThunk(
	"postReviews/getTop10PostReviews",
	async ({ params, signal }, { rejectWithValue }) => {
		try {
			const response = await APIGuest.post("/member/instRev/list", {
				params,
				signal,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message.data);
		}
	}
);
export const addPostReviews = createAsyncThunk(
	"postReviews/addPostReviews",
	async (params, { rejectWithValue }) => {
		try {
			const response = await API.post(`/user/instant/add`, params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message.data);
		}
	}
);
export const getPostReviewsDetails = createAsyncThunk(
	"postReviews/getPostReviewsDetails",
	async ({ id, signal }, { rejectWithValue }) => {
		try {
			const response = await APIGuest.get(`/user/instant/${id}`, { signal });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message.data);
		}
	}
);

export const getInstantReviewRewards = createAsyncThunk(
	"postReviews/getInstantReviewRewards",
	async (signal, { rejectWithValue }) => {
		try {
			const response = await API.get("/member/review/reward", { signal });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message.data);
		}
	}
);

export const getPostReviewsComments = createAsyncThunk(
	"postReviews/getPostReviewsComments",
	async ({ rev_id, signal }, { rejectWithValue }) => {
		try {
			const response = await APIGuest.get(`/user/comments/${rev_id}`, {
				signal,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message.data);
		}
	}
);
export const addPostReviewsComments = createAsyncThunk(
	"postReviews/addPostReviewsComments",
	async (params, { rejectWithValue, dispatch }) => {
		try {
			const response = await API.post(`/user/comment`, params);
			// await dispatch(getPostReviewsComments());
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message.data);
		}
	}
);
export const deletePostReviewsComments = createAsyncThunk(
	"postReviews/deletePostReviewsComments",
	async (id, { rejectWithValue, dispatch }) => {
		try {
			const response = await API.delete(`/user/comment/${id}`);
			// await dispatch(getPostReviewsComments());
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message.data);
		}
	}
);
export const editPostReviewsComments = createAsyncThunk(
	"postReviews/editPostReviewsComments",
	async (params, { rejectWithValue, dispatch }) => {
		try {
			const response = await API.put(`/user/comment/${params.id}`, params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message.data);
		}
	}
);

export const uploadProfilePhoto = createAsyncThunk(
	"postReviews/uploadProfilePhoto",
	async (file, { rejectWithValue }) => {
		try {
			const formData = new FormData();
			formData.append("file", file);
			const response = await API.post("/service/upload", formData);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
export const uploadVoiceMemo = createAsyncThunk(
	"postReviews/uploadVoiceMemo",
	async (file, { rejectWithValue }) => {
		try {
			const formData = new FormData();
			formData.append("file", file);
			const response = await API.post("/service/upload/voice", formData);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
export const uploadVoices = createAsyncThunk(
	"postReviews/uploadVoices",
	async (voices, { rejectWithValue }) => {
		try {
			const response = await API.post("/client/voice", voices);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
export const instantReviewLike = createAsyncThunk(
	"postReviews/instantReviewLike",
	async (id, { rejectWithValue }) => {
		try {
			const response = await API.post(`/user/like/instant/${id}`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const uploadImages = createAsyncThunk(
	"postReviews/uploadImages",
	async (file, { rejectWithValue }) => {
		try {
			const formData = new FormData();
			formData.append("file", file);

			const response = await API.post("/service/upload", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});

			return response.data;
		} catch (error) {
			return rejectWithValue(error.response?.data?.message || "Upload failed");
		}
	}
);
