import { createSlice } from "@reduxjs/toolkit";

import {
  getAllHeartRanks,
  purchaseHearts,
  sendHearts,
  addHearts,
  getHeartBalance,
  getHeartHistories,
  getHeartRank,
  getHeartTransactions,
} from "./actions";

const initialState = {
  hearts: {},
  purchase: null,
  send: null,
  add: null,
  balance: [],
  transactions: null,
  histories: null,
  rank: null,

  loading: "idle" | "pending" | "succeeded" | "failed",
  error: null,
  msg: "",
};

export const heartSlice = createSlice({
  name: "hearts",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllHeartRanks.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllHeartRanks.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.hearts = action.payload;
      })
      .addCase(getAllHeartRanks.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(purchaseHearts.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(purchaseHearts.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.purchase = action.payload;
      })
      .addCase(purchaseHearts.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload;
        state.msg = action?.message;
      })

      .addCase(sendHearts.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(sendHearts.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.send = action.payload;
      })
      .addCase(sendHearts.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload 
        state.msg = action?.message;
   
      })

      .addCase(addHearts.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addHearts.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.add = action.payload;
      })
      .addCase(addHearts.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getHeartBalance.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getHeartBalance.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.balance = action.payload;
      })
      .addCase(getHeartBalance.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getHeartTransactions.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getHeartTransactions.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.transactions = action.payload;
      })
      .addCase(getHeartTransactions.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getHeartHistories.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getHeartHistories.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.histories = action.payload;
      })
      .addCase(getHeartHistories.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getHeartRank.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getHeartRank.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.rank = action.payload;
      })
      .addCase(getHeartRank.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      });
  },
});

// export const { logoutSuccess, updateData } = postsSlice.actions;
export default heartSlice.reducer;
