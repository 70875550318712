import { createAsyncThunk } from "@reduxjs/toolkit";
import APIGuest from "../../apis/apiGuest";

export const getAllSpecialCatalogues = createAsyncThunk(
	"specialCat/getAllSpecialCatalogues",
	async (params, rejectWithValue) => {
		try {
			const response = await APIGuest.post("/specialCat/typeList", params);
			return response.data;
		} catch (error) {
			if (error.response.status === 404) {
				return rejectWithValue(error.message);
			}
			if (error.response.status === 500) {
				return rejectWithValue("The server is down. Please try again later.");
			} else {
				return rejectWithValue(error.message.data);
			}
		}
	}
);

export const getAllSpecialCataloguesClients = createAsyncThunk(
	"specialCat/getAllSpecialCataloguesClients",
	async ({ params, signal }, rejectWithValue) => {
		console.log("params params", params);
		try {
			const response = await APIGuest.post("/member/clients/find2", params, { signal });
			return response.data;
		} catch (error) {
			if (error.response.status === 404) {
				return rejectWithValue(error.message);
			}
			if (error.response.status === 500) {
				return rejectWithValue("The server is down. Please try again later.");
			} else {
				return rejectWithValue(error.message.data);
			}
		}
	}
);
