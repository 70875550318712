import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UnauthorizedPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="container px-0 pt-3 text-center m-top-60">
      <div className="row justify-content-center">
        <div className="col-12 px-5">
          <h1 className="fs-1 text-decoration-none t-color-red">
            {t("Unauthorized")}
          </h1>
          <p className="text-14 text-white">{t("noAccessPermission")}</p>
          <p className="text-14 text-white">{t("LoginToProceed")}</p>
          <div className="d-flex flex-row justify-content-center gap-3 mt-5">
            <button
              className="btn text-12 btn-primary px-5 w-100 w-auto w-lg-25"
              onClick={() => navigate(-1)}
            >
              {t("BACK")}
            </button>
            <button
              className="btn text-12 btn-primary px-5 w-100 w-auto w-lg-25"
              onClick={() => navigate("/login")}
            >
              {t("Login")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
