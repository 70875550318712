// third party package
import React, { useState, Suspense, lazy, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, Provider } from "react-redux";
import jwtDecode from "jwt-decode";
import store from "./redux/store";

// components
import { AuthProvider } from "./shared/auth/auth";
import { tokenGuest } from "./redux/features/home/actions";
import { isTokenExpired } from "./shared/auth/tokenExpired";
import UnauthorizedPage from "./components/pages/unauthorized/UnauthorizedPage";
import ScreenLoaderMainPage from "./components/reusable/ScreenLoaderMainPage";
import NotFoundRoute from "./components/pages/NotFoundRoute";
import ScreenLoader from "./components/reusable/ScreenLoader";
// import ReactGA from 'react-ga4';
// ReactGA.initialize('G-XJL5X8KQ2E');

// import PageNotfound from "./components/commen/PageNotfound";
const LazyComponents = {
	Login: lazy(() => import("./components/Login")),
	Layout: lazy(() => import("./components/Layout")),
	HomePage: lazy(() => import("./components/pages/home/HomePage")),
	PublicLayout: lazy(() => import("./components/PublicLayout")),
	FlowerRankingList: lazy(() => import("./components/pages/flower-ranking/FlowerRankingList")),
	PromotionsList: lazy(() => import("./components/pages/promotions/PromotionsList")),
	Shops: lazy(() => import("./components/pages/shop/Shops")),
	InstantPostView: lazy(() => import("./components/pages/instant-post/InstantPostView")),
	InstantPostLists: lazy(() => import("./components/pages/instant-post/InstantPostLists")),
	Register: lazy(() => import("./components/Register")),
	InstantReviewList: lazy(() => import("./components/pages/instant-review/InstantReviewList")),
	InstantReviewDetails: lazy(() =>
		import("./components/pages/instant-review/InstantReviewDetails")
	),
	ShopList: lazy(() => import("./components/pages/shop/ShopList")),
	SpecialCatalogueList: lazy(() =>
		import("./components/pages/special-catalogue/SpecialCatalogueList")
	),
	SpecialCatalogue: lazy(() => import("./components/pages/special-catalogue/SpecialCatalogue")),
	MemberBenefits: lazy(() => import("./components/pages/member/MemberBenefits")),
	MemberPage: lazy(() => import("./components/pages/member/MemberPage")),
	AdBlog: lazy(() => import("./components/pages/flower-ranking/AdBlog")),
	MemberQRCode: lazy(() => import("./components/pages/member/MemberQRCode")),
	MemberFollowing: lazy(() => import("./components/pages/member/MemberFollowing")),
	ChangePassword: lazy(() => import("./components/ChangePassword")),
	ChangeRegisterNumber: lazy(() => import("./components/ChangeRegisterNumber")),
	MemberProfiles: lazy(() => import("./components/pages/member/MemberProfile")),
	MemberBuy: lazy(() => import("./components/pages/buy/MemberBuy")),
	MacauShops: lazy(() => import("./components/pages/macau/MacauShops")),
	ChinaShops: lazy(() => import("./components/pages/china/ChinaShops")),
	ContactUs: lazy(() => import("./components/pages/contact-us/ContactUs")),
	NewGirls: lazy(() => import("./components/pages/new-girl/NewGirls")),
	SystemNotificationList: lazy(() =>
		import("./components/pages/system-notification/SystemNotificationList")
	),
	MemberEditProfile: lazy(() => import("./components/pages/member/MemberEditProfile")),
	LocationPage: lazy(() => import("./components/pages/location/LocationPage")),
	BuildingPage: lazy(() => import("./components/pages/location/BuildingPage")),
	ReloadOneCoin: lazy(() => import("./components/pages/oneCoin/ReloadOneCoin")),
	ReloadCoinImageSelector: lazy(() =>
		import("./components/pages/oneCoin/ClientReloadCoinImageSelector")
	),
	SuccessReloadOneCoin: lazy(() => import("./components/pages/oneCoin/SuccessReloadOneCoin")),
	TypeAndRegion: lazy(() => import("./components/pages/region-types/TypeAndRegion")),
	NearBy: lazy(() => import("./components/pages/nearByLocation/NearBy")),
	AllFilters: lazy(() => import("./components/pages/filters/AllFilters")),
	MyInstantReview: lazy(() => import("./components/pages/instant-review/MyInstantReview")),
	PromotionsList1: lazy(() => import("./components/pages/promotions/PromotionsList1")),
	CreateInstantReview: lazy(() => import("./components/pages/instant-review/CreateInstantReview")),
	ForgetPassword: lazy(() => import("./components/ForgotPassword")),
	SystemNotifDetails: lazy(() =>
		import("./components/pages/system-notification/SystemNotifDetails")
	),
	Training: lazy(() => import("./components/pages/training/Training")),
	ServiceTypes: lazy(() => import("./components/pages/services/ServiceTypes")),
	Types: lazy(() => import("./components/pages/types/Types")),
	ReloadFlowers: lazy(() => import("./components/pages/buy/ReloadFlowers")),
	ReloadHearts: lazy(() => import("./components/pages/buy/ReloadHearts")),
	MyInstantReviewClient: lazy(() =>
		import("./components/pages/instant-review/MyInstantReviewClient")
	),
	MyInstantReviewUser: lazy(() => import("./components/pages/instant-review/MyInstantReviewUser")),
	RegionOne: lazy(() => import("./components/pages/region/RegionOne")),
	RegionTwo: lazy(() => import("./components/pages/region/RegionTwo")),
	TopRecommendation: lazy(() => import("./components/pages/top-recommendation/TopRecommendation")),
	ChangeRegisterEmail: lazy(() => import("./components/ChangeRegisterEmail")),
	InvitationList: lazy(() => import("./components/pages/invitations/InvitationList")),
	MemberScanner: lazy(() => import("./components/pages/member/MemberScanner")),
	BlogLIstPage: lazy(() => import("./components/pages/blog-page/BlogLIstPage")),
	BlogDetails: lazy(() => import("./components/pages/blog-page/BlogDetails")),
	AllHotelG: lazy(() => import("./components/pages/hotelG/AllHotelG")),
	Massage: lazy(() => import("./components/pages/massage/Massage")),
	WalkUp: lazy(() => import("./components/pages/walk-up/WalkUp")),
	StreetName: lazy(() => import("./components/pages/top-recommendation/FilteredStreetNames")),
	NoStreetName: lazy(() => import("./components/pages/top-recommendation/NoStreetNameFilter")),
	AllChinaClub: lazy(() => import("./components/pages/homeChinaClub/AllHomeChinaClub")),
	AllChinaKTV: lazy(() => import("./components/pages/homeChinaKTV/AllHomeChinaKTV")),
	AllForeigners: lazy(() => import("./components/pages/foreigner/AllForeigners")),
	FAQList: lazy(() => import("./components/pages/faq/FAQ")),
	SPA_ThaiMassage: lazy(() => import("./components/pages/spaAndThaiMassage/SPA_ThaiMassage")),
	ChineseHC_FootMassage: lazy(() =>
		import("./components/pages/chineseHCAndFootMassage/ChineseHC_FootMassage")
	),
	PTB_OutCallMassage: lazy(() =>
		import("./components/pages/ptbAndOutCallMassage/PTB_OutCallMassage")
	),
	LGBT: lazy(() => import("./components/pages/lgbt/lgbt")),
};

function App() {
	const dispatch = useDispatch();

	const [hasTokenGuest, setHasTokenGuest] = useState(false);
	const [showLoader, setShowLoader] = useState(false);

	let guestToken;
	const location = useLocation();

	const ProtectedRoute = ({ children }) => {
		const tokenExpired = isTokenExpired();
		return tokenExpired ? <UnauthorizedPage /> : children;
	};

	const setupTokenGuest = () => {
		dispatch(tokenGuest()).then(() => {
			guestToken = localStorage.getItem("TOKEN_GUEST");
			if (guestToken) setHasTokenGuest(true);
		});
	};

	useEffect(() => {
		if (!hasTokenGuest) setupTokenGuest();
	}, [hasTokenGuest]);

	useEffect(() => {
		guestToken = localStorage.getItem("TOKEN_GUEST");
		if (guestToken !== null) {
			const decodedToken = jwtDecode(guestToken);
			if (decodedToken.exp < Date.now() / 1000) {
				setupTokenGuest();
			}
		} else {
			setHasTokenGuest(false);
		}
	}, [location]);

	return (
		<AuthProvider>
			<Provider store={store}>
				<div className="wrapper">
					<Suspense fallback={<ScreenLoader />}>
						{hasTokenGuest && (
							<Routes>
								{/* Private Routes */}

								<Route path="/" element={<LazyComponents.Layout />}>
									<Route index element={<LazyComponents.HomePage />} />
									<Route path="/ad-blog/:id" element={<LazyComponents.AdBlog />} />
									<Route path="/flower-list" element={<LazyComponents.FlowerRankingList />} />
									<Route path="/promotion-list" element={<LazyComponents.PromotionsList1 />} />
									<Route path="/promotion-list-1" element={<LazyComponents.PromotionsList />} />
								</Route>

								<Route path="/" element={<LazyComponents.Layout />}>
									{/* Private Routes for authenticated users */}
									{/* <Route
                    path="/ad-blog/:id"
                    element={<LazyComponents.AdBlog />}
                  /> */}

									<Route path="/shops/:id" element={<LazyComponents.Shops />} />
									<Route path="/shop-list" element={<LazyComponents.ShopList />} />
									<Route
										path="/detail-instant-post/:id"
										element={<LazyComponents.InstantPostView />}
									/>
									<Route path="/post-list/:type" element={<LazyComponents.InstantPostLists />} />
									<Route
										path="/add-instant-review/:id"
										element={<LazyComponents.CreateInstantReview />}
									/>
									<Route
										path="/client-instant-reviews/:id"
										element={<LazyComponents.MyInstantReviewClient />}
									/>
									<Route
										path="/instant-reviews/:id"
										element={<LazyComponents.InstantReviewDetails />}
									/>
									<Route
										path="/instant-review-list/:type"
										element={<LazyComponents.InstantReviewList />}
									/>
									<Route
										path="/my-instant-reviews"
										element={
											<ProtectedRoute>
												<LazyComponents.MyInstantReview />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/user-instant-reviews/:id"
										element={<LazyComponents.MyInstantReviewUser />}
									/>
									<Route path="/special-cat/:type" element={<LazyComponents.SpecialCatalogue />} />
									<Route
										path="/special-cat/:type/:id"
										element={<LazyComponents.SpecialCatalogue />}
									/>
									<Route
										path="/special-cat-list"
										element={<LazyComponents.SpecialCatalogueList />}
									/>
									<Route path="/member-benefits" element={<LazyComponents.MemberBenefits />} />

									<Route path="/all-filters" element={<LazyComponents.AllFilters />} />
									<Route path="/recommendation" element={<LazyComponents.TopRecommendation />} />
									<Route path="/hotelg" element={<LazyComponents.AllHotelG />} />
									<Route path="/141-walkUp" element={<LazyComponents.WalkUp />} />
									<Route path="/massage" element={<LazyComponents.Massage />} />
									<Route path="/spa&thaiMassage" element={<LazyComponents.SPA_ThaiMassage />} />
									<Route
										path="/chinesehc&footmassage"
										element={<LazyComponents.ChineseHC_FootMassage />}
									/>
									<Route
										path="/parttimebuddy&outcallmassage"
										element={<LazyComponents.PTB_OutCallMassage />}
									/>
									<Route path="/lgbt" element={<LazyComponents.LGBT />} />
									<Route path="/chinaClub" element={<LazyComponents.AllChinaClub />} />
									<Route path="/chinaKTV" element={<LazyComponents.AllChinaKTV />} />
									<Route path="/foreigners" element={<LazyComponents.AllForeigners />} />
									<Route path="/regionOne/:region" element={<LazyComponents.RegionOne />} />
									<Route path="/regionTwo/:region" element={<LazyComponents.RegionTwo />} />
									<Route path="/filtered/streetNames" element={<LazyComponents.StreetName />} />
									<Route
										path="/filtered/no-street-name"
										element={<LazyComponents.NoStreetName />}
									/>
									<Route path="/service-type/:type" element={<LazyComponents.ServiceTypes />} />
									<Route path="/types/:type" element={<LazyComponents.Types />} />
									<Route path="/contact-us" element={<LazyComponents.ContactUs />} />
									<Route path="/nearby" element={<LazyComponents.NearBy />} />

									<Route path="/type-tag" element={<LazyComponents.TypeAndRegion />} />

									<Route path="/building/:b" element={<LazyComponents.BuildingPage />} />
									<Route path="/location/:id" element={<LazyComponents.LocationPage />} />
									<Route path="/macau-shop" element={<LazyComponents.MacauShops />} />
									<Route path="/girl-friend-ktv" element={<LazyComponents.ChinaShops />} />

									{/* Protect Route */}
									<Route
										path="/member"
										element={
											<ProtectedRoute>
												<LazyComponents.MemberPage />
											</ProtectedRoute>
										}
									/>

									<Route
										path="/member-buy"
										element={
											<ProtectedRoute>
												<LazyComponents.MemberBuy />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/member-following"
										element={
											<ProtectedRoute>
												<LazyComponents.MemberFollowing />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/member-qr"
										element={
											<ProtectedRoute>
												<LazyComponents.MemberQRCode />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/profile-member"
										element={
											<ProtectedRoute>
												<LazyComponents.MemberProfiles />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/member-scanner"
										element={
											<ProtectedRoute>
												<LazyComponents.MemberScanner />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/reload-oneCoin"
										element={
											<ProtectedRoute>
												<LazyComponents.ReloadOneCoin />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/reload-onecoin/success"
										element={
											<ProtectedRoute>
												<LazyComponents.SuccessReloadOneCoin />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/reload-onecoin/image-selector"
										element={
											<ProtectedRoute>
												<LazyComponents.ReloadCoinImageSelector />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/reload-flower"
										element={
											<ProtectedRoute>
												<LazyComponents.ReloadFlowers />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/reload-heart"
										element={
											<ProtectedRoute>
												<LazyComponents.ReloadHearts />
											</ProtectedRoute>
										}
									/>

									<Route
										path="/new-girls"
										element={
											<ProtectedRoute>
												<LazyComponents.NewGirls />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/notification"
										element={
											<ProtectedRoute>
												<LazyComponents.SystemNotificationList />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/notification/:id"
										element={
											<ProtectedRoute>
												<LazyComponents.SystemNotifDetails />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/invitations"
										element={
											<ProtectedRoute>
												<LazyComponents.InvitationList />
											</ProtectedRoute>
										}
									/>

									<Route
										path="/change-password"
										element={
											<ProtectedRoute>
												<LazyComponents.ChangePassword />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/change-register-number"
										element={
											<ProtectedRoute>
												<LazyComponents.ChangeRegisterNumber />
											</ProtectedRoute>
										}
									/>
									<Route
										path="/change-register-email"
										element={
											<ProtectedRoute>
												<LazyComponents.ChangeRegisterEmail />
											</ProtectedRoute>
										}
									/>
								</Route>

								{/* Public Routes */}
								<Route path="/" element={<LazyComponents.Layout />}>
									<Route path="/blog" element={<LazyComponents.BlogLIstPage />} />
									<Route path="/blog/:id" element={<LazyComponents.BlogDetails />} />
									<Route path="/faq" element={<LazyComponents.FAQList />} />
									<Route path="/login" element={<LazyComponents.Login />} />
									<Route path="/register" element={<LazyComponents.Register />} />
									<Route path="/forget-password" element={<LazyComponents.ForgetPassword />} />
									<Route path="/training" element={<LazyComponents.Training />} />
								</Route>

								{/* Not Found Route (placed at the end) */}
								<Route path="*" element={<NotFoundRoute />} />
							</Routes>
						)}
					</Suspense>
				</div>
			</Provider>
		</AuthProvider>
	);
}

export default App;
