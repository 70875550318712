import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/api";

//   coin Recharge
export const getCoinRecharge = createAsyncThunk(
	"coins/getCoinRecharge",
	async (params, { rejectWithValue }) => {
		try {
			const response = await API.post(`/product/coin/recharge`, params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

//   coin Recharge Real
export const getRealCoinRecharge = createAsyncThunk(
	"coins/getRealCoinRecharge",
	async (params, { rejectWithValue }) => {
		try {
			const response = await API.post(`/product/oneCoin/topup`, params);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

//    coin balance
export const getCoinBalance = createAsyncThunk("coins/getCoinBalance", async (rejectWithValue) => {
	try {
		const response = await API.get(`/product/coin/balance`);
		return response.data;
	} catch (error) {
		return rejectWithValue(error.response.data?.message);
	}
});

//   coin transactions
export const getCoinTransactions = createAsyncThunk(
	"coins/getCoinTransactions",
	async (params, { rejectWithValue }) => {
		try {
			const response = await API.get(`/product/coin/transactions`, {
				params,
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const getTChatLanguageList = createAsyncThunk(
	"profile/getTChatLanguageList",
	async (rejectWithValue) => {
		try {
			const response = await API.get(`/chat/lang/list`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const updateTChatLanguage = createAsyncThunk(
	"profile/updateTChatLanguage",
	async (lang_abbr, { rejectWithValue }) => {
		try {
			const response = await API.post(`/chat/lang/edit`, { lang_abbr });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const getSelectedTChatLanguage = createAsyncThunk(
	"profile/getSelectedTChatLanguage",
	async (rejectWithValue) => {
		try {
			const response = await API.get(`/chat/user/lang/get`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

//  Get Member Wallet

export const getMemberWallet = createAsyncThunk(
	"coins/getMemberWallet",
	async (params, { rejectWithValue }) => {
		try {
			const response = await API.get(`/product/wallet`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
