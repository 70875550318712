import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/api";
import APIGuest from "../../apis/apiGuest";

//  Get Flower ranking list
export const getAllFlowerRanks = createAsyncThunk(
  "flowers/getAllFlowerRanks",
  async ({ params, signal }, { rejectWithValue }) => {
    try {
      // const response = await APIGuest.get('/product/flower/ranks', { params });
      const response = await APIGuest.post(
        "/top-flower-rankings-list",
        params,
        { signal }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);

//  get flower ranking user details
export const getAdBlogDetails = createAsyncThunk(
  "flowers/getAdBlogDetails",
  async (params, { rejectWithValue }) => {
    try {
      const response = await APIGuest.post(`/member/cp/describe`, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);

// get disclaimer list
export const getClientDisclaimer = createAsyncThunk(
  "flowers/getClientDisclaimer",
  async (type, { rejectWithValue }) => {
    // console.log("TYPE DFD ", type);
    try {
      const response = await APIGuest.get(`/modules/disclaimer?search=${type}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);

//    get Product ID
export const getProductIDFlowerAndHeart = createAsyncThunk(
  "flowers/getProductID",
  async (productNames, { rejectWithValue }) => {
    try {
      const response = await API.get(
        `/modules/productId?search=${productNames}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);
//    flower balance
export const getFlowerBalance = createAsyncThunk(
  "flowers/getFlowerBalance",
  async (rejectWithValue) => {
    try {
      const response = await API.get(`/product/flower/balance`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);

//  purchase flower
export const purchaseFlowers = createAsyncThunk(
  "flowers/purchaseFlowers",
  async (params, { rejectWithValue, dispatch }) => {

	console.log("params", params);
	
    try {
      const response = await API.post(`/product/flower/purchase/`, params);
    //   await dispatch(getFlowerBalance);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
//  send  flower
export const sendFlowers = createAsyncThunk(
  "flowers/sendFlowers",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await API.post(`/product/flower/send`, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);
//  add  flower
export const addFlowers = createAsyncThunk(
  "flowers/addFlowers",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.post(`/product/flower/add`, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);

//   flower transactions
export const getFlowerTransactions = createAsyncThunk(
  "flowers/getFlowerTransactions",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`/product/flower/transactions`, {
        params,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);
//   flower histories
export const getFlowerHistories = createAsyncThunk(
  "flowers/getFlowerHistories",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`/product/flower/histories`, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);

//   flower rank
export const getFlowerRank = createAsyncThunk(
  "flowers/getFlowerRank",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.get(`/product/flower/rank`, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);

export const getTChatURL = createAsyncThunk(
  "adblog/getTChatURL",
  async (params, { rejectWithValue }) => {
    try {
      const response = await API.post(`/chat/url/get`, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data?.message);
    }
  }
);
