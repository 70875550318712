import { createSlice } from "@reduxjs/toolkit";

import {
  getNotificationList,
  notificationDetails,
  deleteNotification,
  deleteAllReadNotifications,
} from "./actions";

const initialState = {
  notifications: {},
  notiDetails: null,
  deleteNoti: null,
  deleteAllNoti: null,
  loading: "idle" | "pending" | "succeeded" | "failed",
  error: null,
  msg: "",
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getNotificationList.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getNotificationList.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.notifications = action.payload;
        state.msg = " ";
      })
      .addCase(getNotificationList.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action?.payload;
        state.msg = action?.message;
      })
      .addCase(notificationDetails.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(notificationDetails.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.notiDetails = action.payload;
        state.msg = " ";
      })
      .addCase(notificationDetails.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(deleteNotification.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.deleteNoti = action.payload;
        state.msg = " ";
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(deleteAllReadNotifications.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deleteAllReadNotifications.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.deleteAllNoti = action.payload;
        state.msg = " ";
      })
      .addCase(deleteAllReadNotifications.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      });
  },
});

export default notificationSlice.reducer;
