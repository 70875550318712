import jwt_decode from "jwt-decode";

const isTokenExpired1 = (token) => {
	try {
		const decodedToken = jwt_decode(token);
		const currentTime = Date.now() / 1000; // Convert to seconds
		// const expirationDate = new Date(decodedToken.exp * 1000);

		// Compare the current time with the token's expiration time (exp claim)
		if (decodedToken.exp < currentTime) {
			return true; // Token has expired
		} else {
			return false; // Token is still valid
		}
	} catch (error) {
		return true; // Invalid token or error occurred (consider it expired)
	}
};

const isTokenExpired = () => {
	const token = localStorage.getItem("TOKEN");
	const isLoggedIn = localStorage.getItem("isLoggedIn");
	const expTokenInMillis = parseInt(localStorage.getItem("EXP_TOKEN"), 10); // Convert to integer

	if (!isLoggedIn || !expTokenInMillis || !token) {
		return true;
	}

	try {
		const currentTimeInSeconds = Math.floor(Date.now() / 1000); // Convert current time to seconds

		// Convert expiration time from milliseconds to seconds
		const expTokenInSeconds = Math.floor(expTokenInMillis / 1000);

		// Compare the token's expiration time with the current time
		if (expTokenInSeconds < currentTimeInSeconds) {
			return true; // Token has expired
		} else {
			return false; // Token is still valid
		}
	} catch (error) {
		return true; // Invalid token or error occurred (consider it expired)
	}
};

const isGuestTokenExpired = () => {
	const token = localStorage.getItem("TOKEN_GUEST");
	const expTokenInMillis = parseInt(localStorage.getItem("EXP_TOKEN"), 10); // Convert to integer

	if (!expTokenInMillis || !token) {
		return true;
	}

	try {
		const currentTimeInSeconds = Math.floor(Date.now() / 1000); // Convert current time to seconds

		// Convert expiration time from milliseconds to seconds
		const expTokenInSeconds = Math.floor(expTokenInMillis / 1000);

		// Compare the token's expiration time with the current time
		if (expTokenInSeconds < currentTimeInSeconds) {
			return true; // Token has expired
		} else {
			return false; // Token is still valid
		}
	} catch (error) {
		return true; // Invalid token or error occurred (consider it expired)
	}
};

const isCheckGuestsTokenExpired = (token) => {
	const decodedToken = parseJwt(token);
	if (!decodedToken.exp) {
		return true; // Token doesn't have an expiration time
	}

	const currentTime = Math.floor(Date.now() / 1000); // Convert current time to seconds
	return decodedToken.exp < currentTime; // Compare expiration time with current time
};

function parseJwt(token) {
	try {
		return JSON.parse(atob(token.split(".")[1]));
	} catch (e) {
		return {};
	}
}

//   const token = localStorage.getItem('TOKEN_GUEST');
//   const checkGuestTokenExpired = isCheckGuestsTokenExpired(token);
export { isTokenExpired, isTokenExpired1, isGuestTokenExpired, isCheckGuestsTokenExpired };
