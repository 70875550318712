import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/api";
import APIGuest from "../../apis/apiGuest";

export const getAllPosts = createAsyncThunk(
	"posts/getAllPosts",
	async ({ params, signal }, { rejectWithValue }) => {
		// console.log("post Parmas ", params);
		try {
			const response = await APIGuest.get("/member/posts", { params, signal });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
export const getAllFollowingPosts = createAsyncThunk(
	"posts/getAllFollowingPosts",
	async ({ params, signal }, { rejectWithValue }) => {
		try {
			const response = await API.get("/member/posts", { params, signal });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
export const getAllClientPosts = createAsyncThunk(
	"posts/getAllClientPosts",
	async (params, { rejectWithValue }) => {
		try {
			const response = await APIGuest.get("/member/posts", { params });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const getCurrentWeekPosts = createAsyncThunk(
	"posts/getCurrentWeekPosts",
	async ({ params, signal }, { rejectWithValue }) => {
		try {
			const response = await APIGuest.get("/member/posts", { params, signal });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);
export const getLastWeekPosts = createAsyncThunk(
	"posts/getLastWeekPosts",
	async ({ params, signal }, { rejectWithValue }) => {
		try {
			const response = await APIGuest.get("/member/posts", { params, signal });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const getInstantPostRewards = createAsyncThunk(
	"posts/getInstantPostRewards",
	async (id, { rejectWithValue }) => {
		try {
			const response = await APIGuest.post(`/member/reward/find`);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const getInstantPostDetails = createAsyncThunk(
	"posts/getInstantPostDetails",
	async ({ id, signal }, { rejectWithValue }) => {
		try {
			const response = await APIGuest.get(`/member/post/${id}`, { signal });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const getInstantPostComments = createAsyncThunk(
	"posts/getInstantPostComments",
	async ({ post_id, signal }, { rejectWithValue }) => {
		try {
			const response = await APIGuest.get(`/post/${post_id}/comments`, { signal });

			return response.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.message);
		}
	}
);

export const addPostInstantComments = createAsyncThunk(
	"posts/addPostInstantComments",
	async (params, { rejectWithValue, dispatch }) => {
		try {
			const response = await API.post(`/post/comment`, params);

			return response.data;
		} catch (error) {
			return rejectWithValue(error.message.data);
		}
	}
);
export const deletePostInstantComments = createAsyncThunk(
	"posts/deletePostInstantComments",
	async (id, { rejectWithValue, dispatch }) => {
		try {
			const response = await API.delete(`/post/comment/${id}`);

			return response.data;
		} catch (error) {
			return rejectWithValue(error.message.data);
		}
	}
);
export const editPostInstantComments = createAsyncThunk(
	"posts/editPostInstantComments",
	async (params, { rejectWithValue, dispatch }) => {
		try {
			const response = await API.put(`/post/comment/${params.id}`, { content: params.content });
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message.data);
		}
	}
);
