import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/api";
import APIGuest from "../../apis/apiGuest";
import configHeader from "../../apis/config";

export const loginUsers = createAsyncThunk(
  "users/loginUsers",
  async (loginData, { rejectWithValue }) => {
    try {
      const response = await API.post("/auth/login", loginData);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.error.reason);
    }
  }
);
export const changePasswordUsers = createAsyncThunk(
  "users/changePasswordUsers",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await API.post("/user/change/password", formData);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.error.reason);
    }
  }
);
export const changeRegisterMobileUsers = createAsyncThunk(
  "users/changeRegisterMobileUsers",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await API.post("/user/change/mobile", formData);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.error.reason);
    }
  }
);
export const changeRegisterEmailUsers = createAsyncThunk(
  "users/changeRegisterEmailUsers",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await API.post("/user/changeEmail", formData);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.error.reason);
    }
  }
);
export const forgetPasswords = createAsyncThunk(
  "users/forgetPasswords",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await API.post("/user/forgot/password", formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.error.reason);
    }
  }
);

//  Get OTP Code
export const getOTPCode = createAsyncThunk(
  "users/getOTPCode",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await API.post("/sendVerificationCode", userData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.error.reason);
    }
  }
);

//  Get OTP Code
export const getOTPCodeLogin = createAsyncThunk(
  "users/getOTPCodeLogin",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await API.post("/sendVerificationCodeLogin", userData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.error.reason);
    }
  }
);

export const checkValidity = createAsyncThunk(
  "users/checkValidity",
  async (params, { rejectWithValue }) => {
    try {
      const response = await APIGuest.post("/auth/check", params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.error.reason);
    }
  }
);

//  Verify OTP Code
export const verifyOTPCode = createAsyncThunk(
  "users/verifyOTPCode",
  async (userData, { rejectWithValue, dispatch }) => {
    try {
      const response = await API.post("/verifyRegCode", userData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.error.reason);
    }
  }
);
export const createMember = createAsyncThunk(
  "users/createMember",
  async (userData, { rejectWithValue, dispatch }) => {
    try {
      const response = await API.post("/member/register", userData);
      return response.data;
    } catch (err) {
      if (err.response.status === 400) {
        return rejectWithValue(err.response.data.error.reason);
      }
      if (err.response.status === 500) {
        return rejectWithValue("The server is down. Please try again later.");
      } else {
        return rejectWithValue(err.message.data);
      }
    }
  }
);

export const deleteMember = createAsyncThunk(
  "users/deleteMember",
  async (userData, { rejectWithValue, dispatch }) => {
    try {
      const response = await API.post(
        "/member/register",
        userData,
        configHeader
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data.error.reason);
    }
  }
);
