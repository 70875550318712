import { createSlice } from "@reduxjs/toolkit";

import {
  getAllCountries,
  getAllCurrency,
  getAllDisclaimer,
  getAllFeature,
  getAllLanguage,
  getMemberPrice,
  getAllNationality,
  getAllPromotion,
  getAllRegion1,
  getAllRegion2,
  getAllRegion2Plus,
  getAllRemarks,
  getAllServiceType,
  getAllServices,
  getAllClientSpecialCat,
  getAllTypesByCountry,
  getAllTypes,
  getAllFilters,
  getAllTypesV2
} from "./actions";

const initialState = {
  country: null,
  currency: null,
  disclaimer: null,
  features: null,
  languages: null,
  prices: null,
  nationality: null,
  promotions: null,
  region1: null,
  region2: null,
  region2Plus: null,
  remarks: null,
  serviceType: null,
  services: null,
  specialCats: null,
  types: null,
  typesV2: null,
  allfilters: null,

  loading: "idle" | "pending" | "succeeded" | "failed",
  error: null,
  msg: "",
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllCountries.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllCountries.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.country = action.payload;
      })
      .addCase(getAllCountries.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getAllCurrency.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllCurrency.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.currency = action.payload;
      })
      .addCase(getAllCurrency.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getAllDisclaimer.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllDisclaimer.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.disclaimer = action.payload;
      })
      .addCase(getAllDisclaimer.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getAllFeature.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllFeature.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.features = action.payload;
      })
      .addCase(getAllFeature.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getAllLanguage.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllLanguage.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.languages = action.payload;
      })
      .addCase(getAllLanguage.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getMemberPrice.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getMemberPrice.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.prices = action.payload;
      })
      .addCase(getMemberPrice.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getAllNationality.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllNationality.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.nationality = action.payload;
      })
      .addCase(getAllNationality.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getAllPromotion.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllPromotion.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.promotions = action.payload;
      })
      .addCase(getAllPromotion.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getAllRegion1.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllRegion1.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.region1 = action.payload;
      })
      .addCase(getAllRegion1.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getAllRegion2.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllRegion2.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.region2 = action.payload;
      })
      .addCase(getAllRegion2.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getAllRegion2Plus.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllRegion2Plus.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.region2Plus = action.payload;
      })
      .addCase(getAllRegion2Plus.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getAllRemarks.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllRemarks.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.remarks = action.payload;
      })
      .addCase(getAllRemarks.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getAllServiceType.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllServiceType.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.serviceType = action.payload;
      })
      .addCase(getAllServiceType.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getAllServices.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllServices.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.services = action.payload;
      })
      .addCase(getAllServices.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getAllClientSpecialCat.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllClientSpecialCat.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.specialCats = action.payload;
      })
      .addCase(getAllClientSpecialCat.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getAllTypes.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllTypes.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.types = action.payload;
      })
      .addCase(getAllTypes.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getAllTypesByCountry.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllTypesByCountry.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.types = action.payload;
      })
      .addCase(getAllTypesByCountry.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getAllFilters.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllFilters.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.allfilters = action.payload;
      })
      .addCase(getAllFilters.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getAllTypesV2.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllTypesV2.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.typesV2 = action.payload;
      })
      .addCase(getAllTypesV2.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      });
      
  },
});

// export const { logoutSuccess, updateData } = postsSlice.actions;
export default filtersSlice.reducer;
