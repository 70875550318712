import { createSlice } from "@reduxjs/toolkit";

import {
	getMemberQRCode,
	getMemberFollowing,
	getMemberProfile,
	deleteProfilePhoto,
	uploadProfilePhoto,
	editMemberProfile,
	getFollowerFavorite,
	getFollowingStatus,
	followAndUnfollow,
	editMemberProfileF,
	getMemberClients,
	getClientRealPhoto,
	getClientADPhoto,
	getClientADBanner,
	getMemberClientFilters,
	getMemberClientFiltersV1,
	getUserFollowingStatus,
	getShopFollowingStatus,
	getProductOneCoinSend,
	getMemberRecommentationList,
	getMemberForeignersList,
	getMemberHotelGList,
} from "./actions";

const initialState = {
	details: {
		basic_desc: {
			region1: [],
			region2: [],
			type: [],
			allTypes: false,
			allRegion1: false,
			allRegion2: false,
			countrySelect: "",
		},
		items: [],
		totalPage: 0,
	},
	members: {},
	notificationList: false,
	macauShop: {
		totalPages: 0,
		currentPage: 0,
		totalItems: 0,
		items: [],
	},
	chinaShop: {
		totalPages: 0,
		currentPage: 0,
		totalItems: 0,
		items: [],
	},
	memeberQRCode: null,
	memberFollowing: null,
	memberProfile: null,
	uploadPhoto: null,
	deletePhoto: null,
	editProfileData: null,
	userFollowStatus: null,
	shopFollowStatus: null,
	followUnfollow: null,
	followFavorite: null,
	clientLists: null,
	clientTypes: null,
	clientTypesv1: null,
	clientReco: null,
	clientRealPhoto: null,
	clientADPhoto: null,
	clientADBanner: null,
	productOneCoinSend: null,
	allForeignerType: null,
	allHoteGType: null,
	loading: "idle" | "pending" | "succeeded" | "failed",
	error: null,
	msg: "",
	token: localStorage.getItem("TOKEN") || null,
	expToken: localStorage.getItem("EXP_TOKEN") || null,
};

export const memberSlice = createSlice({
	name: "members",
	initialState,
	reducers: {
		setDetailSelectFilter: (state, action) => {
			const {
				selectedRegion1,
				selectedRegion2,
				selectedTypes,
				selectAllRegion1,
				selectAllRegion2,
				selectAllTypes,
				countryValue,
				items,
				totalPage,
			} = action.payload;

			state.details.basic_desc.type = selectedTypes;
			state.details.basic_desc.region1 = selectedRegion1;
			state.details.basic_desc.region2 = selectedRegion2;
			state.details.basic_desc.allTypes = selectAllTypes;
			state.details.basic_desc.allRegion1 = selectAllRegion1;
			state.details.basic_desc.allRegion2 = selectAllRegion2;
			state.details.basic_desc.countrySelect = countryValue;
			state.details.items = items;
			state.details.totalPage = totalPage;
		},
		setCleanSelecFilter: (state) => {
			state.details.basic_desc.type = [];
			state.details.basic_desc.region1 = [];
			state.details.basic_desc.region2 = [];
			state.details.basic_desc.allTypes = false;
			state.details.basic_desc.allRegion1 = false;
			state.details.basic_desc.allRegion2 = false;
			state.details.basic_desc.countrySelect = "";
		},
		setDetailMacauShop: (state, action) => {
			state.macauShop.currentPage = action.payload.currentPage;
			state.macauShop.totalPages = action.payload.totalPages;
			state.macauShop.totalItems = action.payload.totalItems;
			state.macauShop.items = action.payload.filteredNewArray;
		},
		setDetailChinaShop: (state, action) => {
			state.chinaShop.currentPage = action.payload.currentPage;
			state.chinaShop.totalPages = action.payload.totalPages;
			state.chinaShop.totalItems = action.payload.totalItems;
			state.chinaShop.items = action.payload.filteredNewArray;
		},
		setNotificationTrigger: (state, action) => {
			state.notificationList = action.payload;
		},
		setToken: (state, action) => {
			state.token = action.payload.token;
			state.expToken = action.payload.expToken;
		},
		clearToken: (state) => {
			state.token = null;
			state.expToken = null;
			state.memberProfile = null;
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(getMemberProfile.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getMemberProfile.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.memberProfile = action.payload;
				state.msg = " ";
			})
			.addCase(getMemberProfile.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(editMemberProfile.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(editMemberProfile.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.editProfileData = action.payload;
				state.msg = " ";
			})
			.addCase(editMemberProfile.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(editMemberProfileF.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(editMemberProfileF.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.editProfileData = action.payload;
				state.msg = " ";
			})
			.addCase(editMemberProfileF.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action?.payload;
				state.msg = action?.message;
			})
			.addCase(deleteProfilePhoto.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(deleteProfilePhoto.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.deletePhoto = action.payload;
				state.msg = " ";
			})
			.addCase(deleteProfilePhoto.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(uploadProfilePhoto.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(uploadProfilePhoto.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.uploadPhoto = action.payload;
				state.msg = " ";
			})
			.addCase(uploadProfilePhoto.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(getMemberQRCode.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getMemberQRCode.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.memeberQRCode = action.payload;
				state.msg = " ";
			})
			.addCase(getMemberQRCode.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(getMemberFollowing.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getMemberFollowing.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.memberFollowing = action.payload;
				state.msg = " ";
			})
			.addCase(getMemberFollowing.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(getFollowerFavorite.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getFollowerFavorite.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.followFavorite = action.payload;
				state.msg = " ";
			})
			.addCase(getFollowerFavorite.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(getUserFollowingStatus.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getUserFollowingStatus.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.userFollowStatus = action.payload;
				state.msg = " ";
			})
			.addCase(getUserFollowingStatus.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(getShopFollowingStatus.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getShopFollowingStatus.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.shopFollowStatus = action.payload;
				state.msg = " ";
			})
			.addCase(getShopFollowingStatus.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(followAndUnfollow.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(followAndUnfollow.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.followUnfollow = action.payload;
				state.msg = " ";
			})
			.addCase(followAndUnfollow.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload;
				state.msg = action?.message;
			})
			.addCase(getMemberClients.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getMemberClients.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.clientLists = action.payload;
				state.msg = " ";
			})
			.addCase(getMemberClients.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(getMemberClientFilters.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getMemberClientFilters.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.clientTypes = action.payload;
				state.msg = " ";
				// console.log("loadingClientTypes", action.payload);
			})
			.addCase(getMemberClientFilters.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(getMemberClientFiltersV1.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getMemberClientFiltersV1.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.clientTypesv1 = action.payload;
				state.msg = " ";
			})
			.addCase(getMemberClientFiltersV1.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(getMemberRecommentationList.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getMemberRecommentationList.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.clientReco = action.payload;
				state.msg = " ";
			})
			.addCase(getMemberRecommentationList.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(getMemberForeignersList.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getMemberForeignersList.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.allForeignerType = action.payload;
				state.msg = " ";
			})
			.addCase(getMemberForeignersList.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(getMemberHotelGList.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getMemberHotelGList.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.allHoteGType = action.payload;
				state.msg = " ";
			})
			.addCase(getMemberHotelGList.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(getClientRealPhoto.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getClientRealPhoto.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.clientRealPhoto = action.payload;
				state.msg = "posts data successfully fetched ";
			})
			.addCase(getClientRealPhoto.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(getClientADPhoto.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getClientADPhoto.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.clientADPhoto = action.payload;
				state.msg = "posts data successfully fetched ";
			})
			.addCase(getClientADPhoto.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(getClientADBanner.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getClientADBanner.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.clientADBanner = action.payload;
				state.msg = "posts data successfully fetched ";
			})
			.addCase(getClientADBanner.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			})
			.addCase(getProductOneCoinSend.pending, (state) => {
				state.loading = "pending";
			})
			.addCase(getProductOneCoinSend.fulfilled, (state, action) => {
				state.loading = "succeeded";
				state.productOneCoinSend = action.payload;
				state.msg = "posts data successfully fetched ";
			})
			.addCase(getProductOneCoinSend.rejected, (state, action) => {
				state.loading = "failed";
				state.error = action.payload?.message;
				state.msg = action?.message;
			});
	},
});

// export const { logoutSuccess, updateData } = postsSlice.actions;
export const {
	setDetailSelectFilter,
	setCleanSelecFilter,
	setDetailMacauShop,
	setDetailChinaShop,
	setNotificationTrigger,
	setToken,
	clearToken,
} = memberSlice.actions;
export default memberSlice.reducer;
