// src/features/auth/authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogin: false,
  created: null,
  email: null,
  membership: null,
  mobile: null,
  modified: null,
  photo: null,
  username: null,
  zh_name: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginDetails: (state, action) => {
      return { ...state, isLogin: true, ...action.payload };
    },
    logoutDetials: () => initialState,
  },
});

export const { loginDetails, logoutDetials } = authSlice.actions;
export default authSlice.reducer;
