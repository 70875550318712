import { createSlice } from "@reduxjs/toolkit";

import {
  getCoinRecharge,
  getCoinBalance,
  getCoinTransactions,
  getMemberWallet,
  getRealCoinRecharge
} from "./actions";

const initialState = {
  Coins: {},
  
  recharge: null,
  rechargeReal: null,
  balance: 0,
  transactions: null,
  memberWallet: null,

  loading: "idle" | "pending" | "succeeded" | "failed",
  error: null,
  msg: "",
};

export const coinSlice = createSlice({
  name: "Coins",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getCoinRecharge.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getCoinRecharge.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.recharge = action.payload;
      })
      .addCase(getCoinRecharge.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getCoinBalance.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getCoinBalance.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.balance = action.payload;
      })
      .addCase(getCoinBalance.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })

      .addCase(getCoinTransactions.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getCoinTransactions.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.transactions = action.payload;
      })
      .addCase(getCoinTransactions.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getMemberWallet.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getMemberWallet.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.memberWallet = action.payload;
      })
      .addCase(getMemberWallet.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      })
      .addCase(getRealCoinRecharge.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getRealCoinRecharge.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.rechargeReal = action.payload;
      })
      .addCase(getRealCoinRecharge.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload?.message;
        state.msg = action?.message;
      });
  },
});

// export const { logoutSuccess, updateData } = postsSlice.actions;
export default coinSlice.reducer;
