const authHeader = () => {
	const user = JSON.parse(localStorage.getItem('userInfo'));

	if (user && user.accessToken) {
		return { 'x-access-token': user.accessToken };
	} else {
		return {};
	}
};

export const hashToSixDigit = (hash) =>{
	// 取哈希值的前几位并转换为数值
	const num = parseInt(hash.slice(0, 6 ), 16);
	// 将数值缩小到6位数字范围
	const sixDigitNum = num % 1000000;
	// 将数值格式化为6位数字，不足6位前面补0
	return sixDigitNum.toString().padStart(6 , "0");
}

export default authHeader;
